import React from 'react';

// components
import Body from './body';
import Footer from './footer';
import Header from './header';

// styles
import styles from './page.module.scss';

const Page = ({ className, children, ...props }) => (
    <div className={[styles.page, className].filter(Boolean).join(' ')} {...props}>
        {children}
    </div>
);

Page.Body = Body;
Page.Footer = Footer;
Page.Header = Header;

export default Page;
