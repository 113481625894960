import React from 'react';

const Logo = ({ fill, width = 121, height = 35, onClick }) => (
    <svg width={width} height={height} viewBox="0 0 121 35" fill={fill} xmlns="http://www.w3.org/2000/svg" onClick={onClick} style={{ cursor: 'pointer' }}>
        <g clipPath="url(#clip0_2412_15808)">
            <path d="M27.352 34.9948C27.2694 34.8874 27.1847 34.7882 27.0979 34.6787L27.2355 34.9948H27.352Z" />
            <path d="M32.8029 34.9783C30.6901 30.9824 27.1218 27.9019 22.7996 26.3424C18.4773 24.783 13.7135 24.8572 9.44444 26.5507L11.0114 23.1023L11.365 22.3255L16.6123 11.1292L21.6181 22.3255L21.9866 23.1519C24.4403 23.8022 26.7546 24.8763 28.8199 26.3234L16.7012 -0.00512695L4.20778 26.1726L0.023537 34.9432L0.000244141 34.9949H5.48677L5.53336 34.8957C6.81108 33.3058 8.43891 32.0167 10.2964 31.1237C12.1538 30.2307 14.1933 29.7567 16.2639 29.7369C18.3345 29.717 20.3831 30.1518 22.2582 31.009C24.1332 31.8663 25.7867 33.124 27.0962 34.6891C27.1851 34.7945 27.2698 34.8957 27.3524 35.0052H32.822L32.8029 34.9783Z" />
            <path d="M46.279 13.5425L40.0513 27.5714L33.9168 13.5425H28.5531L38.55 34.995H41.3642L51.4224 13.5425H46.279Z" />
            <path d="M71.4649 18.5197C70.6369 17.0121 69.4062 15.7513 67.9032 14.8709C66.3252 13.967 64.5215 13.5072 62.692 13.5424C60.7805 13.5233 58.898 13.9997 57.2369 14.9228C55.5758 15.8459 54.1956 17.1826 53.2372 18.7965C52.2574 20.4594 51.7563 22.3497 51.7867 24.2676C51.7612 26.1955 52.2736 28.0938 53.269 29.7593C54.2247 31.3669 55.6064 32.6936 57.269 33.6003C59.0098 34.5438 60.974 35.0247 62.9652 34.9949C64.5643 35.003 66.1487 34.6966 67.6237 34.0941C69.0678 33.5081 70.3487 32.596 71.3612 31.4329L68.2844 28.3874C67.6403 29.1014 66.8349 29.6595 65.9318 30.0176C64.9727 30.3955 63.9465 30.5844 62.9122 30.5734C61.7467 30.5962 60.5952 30.3227 59.5708 29.78C58.613 29.2603 57.8331 28.4771 57.3283 27.5279C57.0717 27.0537 56.8775 26.5497 56.7502 26.0279H72.494C72.583 25.5982 72.6493 25.2049 72.6931 24.8482C72.7356 24.4992 72.7575 24.148 72.7587 23.7965C72.7919 21.9586 72.3452 20.1427 71.4607 18.5197H71.4649ZM57.2732 20.8151C57.7491 19.9078 58.4847 19.1549 59.3908 18.6478C60.3709 18.1151 61.4794 17.8482 62.6009 17.8751C63.634 17.843 64.6572 18.0798 65.5655 18.561C66.3762 19.0234 67.0271 19.7111 67.4353 20.5362C67.6929 21.0536 67.8802 21.6016 67.9922 22.1664H56.7693C56.8843 21.6974 57.0571 21.2437 57.2838 20.8151" />
            <path d="M94.88 17.7634C94.186 16.5298 93.1892 15.4834 91.979 14.7179C90.7229 13.9241 89.2529 13.5134 87.7566 13.5382C86.2861 13.517 84.8365 13.8793 83.5576 14.5878C82.7644 15.031 82.0576 15.6074 81.4697 16.2902V13.5423H76.6311V34.9948H81.4697V22.6807C81.4547 21.8299 81.6663 20.9899 82.0838 20.2427C82.4832 19.5389 83.0776 18.9589 83.7989 18.5692C84.5675 18.1613 85.4314 17.9549 86.3061 17.97C87.7178 17.97 88.8683 18.4128 89.7577 19.2985C90.6471 20.1842 91.0939 21.3143 91.0981 22.689V35.003H95.9367V21.6497C95.9306 20.2885 95.566 18.9516 94.8779 17.7675" />
            <path d="M116.163 13.5424V16.2655C115.548 15.5718 114.811 14.9911 113.987 14.5506C112.669 13.8614 111.193 13.5142 109.699 13.5424C107.869 13.5244 106.074 14.027 104.532 14.9886C102.994 15.9468 101.737 17.2772 100.883 18.8502C99.9711 20.5346 99.5094 22.4164 99.5408 24.3213C99.5129 26.2113 99.9747 28.0775 100.883 29.7469C101.734 31.3169 102.992 32.6414 104.532 33.5878C106.08 34.5328 107.873 35.024 109.699 35.0031C111.2 35.0291 112.684 34.6822 114.01 33.9948C114.824 33.5645 115.553 32.9964 116.163 32.3172V35.0031H121V13.5424H116.163ZM114.823 28.7738C113.723 29.9473 112.279 30.5334 110.491 30.532C109.403 30.5564 108.329 30.2846 107.391 29.7469C106.49 29.2144 105.758 28.4502 105.273 27.5382C104.745 26.5293 104.481 25.4085 104.504 24.2758C104.481 23.1438 104.745 22.0238 105.273 21.0155C105.757 20.1031 106.49 19.3387 107.391 18.8068C108.328 18.2657 109.402 17.9902 110.491 18.0114C111.593 17.9833 112.682 18.2552 113.635 18.7965C114.53 19.3262 115.254 20.0918 115.723 21.0052C116.236 22.0184 116.49 23.1377 116.464 24.2676C116.464 26.0981 115.915 27.6002 114.817 28.7738" />
        </g>
        <defs>
            <clipPath id="clip0_2412_15808">
                <rect width="121" height="35" />
            </clipPath>
        </defs>
    </svg>

);

export default Logo;
