import React from 'react';

// components
import Container from 'components/container';

// styles
import styles from './header.module.scss';

const Header = ({ className, children, ...props }) => (
    <div className={[styles.header, className].filter(Boolean).join(' ')} {...props}>
        <Container style={{ justifyContent: 'space-between' }}>
            {children}
        </Container>
    </div>
);

export default Header;
