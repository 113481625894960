/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';

// components
import Page from 'components/page';
import { HStack, VStack } from 'avena-components';
import Logo from 'components/logo';
import Image from 'next/image';
// styles
import styles from './footer.module.scss';

const Footer = ({ pathList }) => {
    const router = useRouter();
    const [isMobile, setIsMobile] = useState(false);


    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (window.innerWidth < 767) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
            window.addEventListener('resize', () => {
                if (window.innerWidth < 767) {
                    setIsMobile(true);
                } else {
                    setIsMobile(false);
                }
            });
        }
    }, []);

    const handleLinkedInClick = () => {
        router.push('https://www.linkedin.com/company/avena-network/');
    };

    return (
        <Page.Footer className={styles.footer}>
            {isMobile ?
                <VStack>
                    <HStack style={{ gap: '13px' }}>
                        <Logo fill='#0066cc' width='124' height='36' />
                        <VStack className={styles.connectContainer}>
                            <div className={styles.connect}>Connecting you to the</div>
                            <div className={styles.connect}>world’s top professionals</div>
                        </VStack>
                    </HStack>
                    <div className={styles.hrSolid} />
                    <VStack className={styles.rightStack}>
                        <span>Learn More</span>
                        <Link href='/about-us'><div>Abous Us</div></Link>
                        <Link href='/our-methodology'><div>Our Methodology</div></Link>
                        <Link href='/#services'><div>Services</div></Link>
                        <Link href='/#our-expertise'><div>Our Expertise</div></Link>
                        <Link href='/#how-to-get-started'><div>How to Get Started</div></Link>
                        <Link href='/#how-we-do-it'><div>How we Do It</div></Link>
                        <span>Our Expertise</span>
                        <Link href='/#web-application-development'><div>Web Application Development</div></Link>
                        <Link href='/#mobile-application-development'><div>Mobile Application Development</div></Link>
                        <Link href='/#artifical-intelligence'><div>Artificial Intelligence (AI) Development</div></Link>
                        <Link href='/#user-experience-and-design'><div>User Experience and Design</div></Link>
                    </VStack>
                    <div className={styles.hrDashed} />
                    <HStack className={styles.inContainer} style={{ marginTop: '0px' }}>
                        <Image width={200} height={200} onClick={handleLinkedInClick} src='/in.webp' alt="in" style={{ width: '22px', height: '22px', cursor: 'pointer' }} />
                        <div onClick={handleLinkedInClick} className={styles.linkedin}>Linkedin</div>
                    </HStack>
                    <Link href='mailto:info@avena.co'><div className={styles.info}>info@avena.co</div></Link>
                    <div className={styles.copyright}>Copyright © 2022 AVENA. All rights reserved.</div>
                </VStack> :
                <VStack>
                    <HStack className={styles.hstack}>
                        <VStack>
                            <HStack style={{ gap: '13px' }}>
                                <Logo fill='#0066cc' width='124' height='36' />
                                <VStack className={styles.connectContainer}>
                                    <div className={styles.connect}>Connecting you to the</div>
                                    <div className={styles.connect}>world’s top professionals</div>
                                </VStack>
                            </HStack>
                            <Link href='mailto:info@avena.co'><div className={styles.info}>info@avena.co</div></Link>
                            <div className={styles.addressContainer}>
                                Dubai Silicon Oasis, DDP, Building A2, <br />
                                Unit 101, Dubai, (UAE)
                            </div>
                        </VStack>

                        <HStack style={{ gap: '80px' }} className={styles.rightStack}>
                            <VStack >
                                <span>Learn More</span>
                                <Link href='/about-us'><div>About Us</div></Link>
                                <Link href='/our-methodology'><div>Our Methodology</div></Link>
                                <Link href='/#portfolio'><div>Portfolio</div></Link>
                                <Link href='/#services'><div>Services</div></Link>
                            </VStack>
                            <VStack>
                                <span></span>
                                <Link href='/#our-expertise'><div>Our Expertise</div></Link>
                                <Link href='/#how-to-get-started'><div>How to Get Started</div></Link>
                                <Link href='/#how-we-do-it'><div>How We Do It</div></Link>
                            </VStack>
                            <VStack>
                                <span>Our Expertise</span>
                                <Link href='/#web-application-development'><div>Web Application Development</div></Link>
                                <Link href='/#mobile-application-development'><div>Mobile Application Development</div></Link>
                                <Link href='/#artifical-intelligence'><div>Artificial Intelligence (AI) Development</div></Link>
                                <Link href='/#user-experience-and-design'><div>User Experience and Design</div></Link>
                            </VStack>
                        </HStack>
                    </HStack>
                    <HStack className={styles.bottomHStack}>
                        <HStack className={styles.inContainer}>
                            <div onClick={handleLinkedInClick} className={styles.linkedin}>Linkedin</div>
                            <Image width={200} height={200} onClick={handleLinkedInClick} src='/in.webp' alt="in" style={{ width: '22px', height: '22px', cursor: 'pointer' }} />
                        </HStack>
                        <div className={styles.copyright}>Copyright © 2023 AVENA. All rights reserved.</div>
                    </HStack>
                    <div className={styles.footerMask}>
                        <Image className={styles.image} alt='footer_mask' src='/footer_mask.webp' width={1920} height={14} />
                    </div>
                </VStack>
            }
        </Page.Footer>
    );
};

export default Footer;
