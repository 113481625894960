import React from 'react';

// components
import Container from 'components/container';

// styles
import styles from './footer.module.scss';

const Footer = ({ className, children, ...props }) => (
    <div className={[styles.footer, className].filter(Boolean).join(' ')} {...props}>
        <Container style={{ justifyContent: 'space-between' }}>
            {children}
        </Container>
    </div>
);

export default Footer;
