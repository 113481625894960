import React from 'react';

const MobileLogo = ({ fill, width = '28', height = '30' }) => (
    <svg width={width} height={height} viewBox="0 0 28 30" fill={fill} xmlns="http://www.w3.org/2000/svg" style={{ cursor: 'pointer' }}>
        <path d="M14.0149 0L0 30H4.87475L4.87604 29.9972C6.9606 27.2105 10.2783 25.4082 14.0149 25.4082C17.7516 25.4082 21.0692 27.2105 23.1538 29.9972L23.1551 30H28C26.2172 26.7824 23.3664 24.2441 19.9291 22.869C18.214 22.1829 16.3528 21.7863 14.4055 21.7394C14.2757 21.7363 14.1455 21.7347 14.0149 21.7347C11.9258 21.7347 9.9302 22.1371 8.10076 22.869L14.0149 9.79592L18.5653 19.8543C20.756 20.4132 22.7902 21.3663 24.5887 22.6341L14.0149 0Z" fill={fill} />
    </svg>

);

export default MobileLogo;
