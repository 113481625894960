// styles
import 'react-toastify/dist/ReactToastify.css';
import 'styles/index.scss'
import styles from 'styles/app.scss';
import dynamic from 'next/dynamic';

// components
import Page from 'components/page';
import Header from 'components/header';
import Footer from 'components/footer';

import * as gtag from "../helpers/ga/gtag";

import { useEffect } from 'react';
import Head from 'next/head'
import { useRouter } from 'next/router';
const ToastContainer = dynamic(() =>import('react-toastify').then((t) => t.ToastContainer));

export default function App({ Component, pageProps }) {
    const router = useRouter();

    useEffect(() => {
        const handleRouteChange = (url) => {
          gtag.pageview(url);
        };
        router.events.on("routeChangeComplete", handleRouteChange);
        return () => {
          router.events.off("routeChangeComplete", handleRouteChange);
        };
    }, [router.events]);

    const pathname = router.pathname;

    const pathList = ['', '/', '/blog', '/contact-us', '/blog/:id', '/how-to-hire', '/upgrade-plan'];
    return (
        <>
            <Head>
                {/* Add google analytics tag */}
                <script
                    strategy="afterInteractive"
                    src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
                />
                <script
                    strategy="afterInteractive"
                    dangerouslySetInnerHTML={{
                    __html: `
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', '${gtag.GA_TRACKING_ID}', {
                        page_path: window.location.pathname,
                        });
                    `,
                    }}
                />
                <title>Avena</title>
                <meta property="og:title" content="Avena" key="title" />
                <meta name='description' content='AVENA delivers unparalleled custom software services to grow your business with transparency, expertise, and high-quality support. Contact us today.' />
                <meta httpEquiv="Permissions-Policy" content="interest-cohort=()" />
            </Head>
            <ToastContainer />
            <Page className={styles.app}>
                <Header style='light' />
                <Page.Body className={styles.body} style={pathList.includes(pathname) || pathname.startsWith('/blog') ? { backgroundColor: 'white' } : null}>
                    <Component {...pageProps} />
                </Page.Body>
                <Footer pathList={pathList} />
            </Page>
        </>
    )
}
