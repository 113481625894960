import React from 'react';

// styles
import styles from './container.module.scss';

const Container = ({ className, children, ...props }) => (
    <div className={[styles.container, className].filter(Boolean).join(' ')} {...props}>
        {children}
    </div>
);

export default Container;
