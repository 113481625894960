/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import Link from 'next/link'

// components
import {
    Button,
    HStack,
    VStack,
    Card,
    Spacer
} from 'avena-components';

import Logo from 'components/logo';
import MobileLogo from 'components/logo/mobile';
import Page from 'components/page';
import { MenuOutlined } from '@ant-design/icons';
import CloseSvg from '/public/svg/close-svg';

// styles
import styles from './header.module.scss';
import { useRouter } from 'next/router';

const Header = ({ style }) => {
    const router = useRouter();

    const [isTablet, setIsTablet] = useState(false);
    const [hamburgerMenuVisible, setHamburgerMenuVisible] = useState(false);

    const handleHamburgerMenuClick = () => {
        setHamburgerMenuVisible(prevState => !prevState);
    };
    const handleHomeClick = () => {
        router.push('/');
        setHamburgerMenuVisible(prevState => !prevState);
    };

    useEffect(() => {
        // if search menu closed and screen is greater than 1023 show searchBlock menu
        function updateSize() {
            if (window.innerWidth > 1350) {
                setIsTablet(false);
            }
            else {
                setIsTablet(true);
            }
        }
        updateSize();
        window.addEventListener('resize', updateSize);
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    return (
        <Page.Header className={[styles.header, styles[style]].filter(Boolean).join(' ')}>
            <Card className={styles.hamburgerMenu} style={(hamburgerMenuVisible && isTablet) ? { width: '100%', gap: '0px' } : null}>
                {hamburgerMenuVisible && isTablet ?
                    <VStack className={styles.VStack}>
                        <VStack>
                            <HStack className={styles.topStack}>
                                <Logo fill='#0066cc' width='104' height='30' onClick={handleHomeClick} />
                                <CloseSvg fill="#7C7B7B" width='16' height='16' onClick={handleHamburgerMenuClick} />
                            </HStack>
                            <hr className={styles.hr} />
                            <Link className={styles.itemContainer} onClick={handleHamburgerMenuClick} href='/about-us'>About Us</Link>
                            <Link className={styles.itemContainer} onClick={handleHamburgerMenuClick} href='/our-methodology'>Our Methodology</Link>
                            <Link className={styles.itemContainer} onClick={handleHamburgerMenuClick} href='/#portfolio'>Portfolio</Link>
                            <Link className={styles.itemContainer} onClick={handleHamburgerMenuClick} href='/#services'>Services</Link>
                            <Link className={styles.itemContainer} onClick={handleHamburgerMenuClick} href='/#our-expertise'>Our Expertise</Link>
                            <Link className={styles.itemContainer} onClick={handleHamburgerMenuClick} href='/#how-to-get-started'>How To Get Started</Link>
                            <Link className={styles.itemContainer} onClick={handleHamburgerMenuClick} href='/#how-we-do-it'>How We Do It</Link>
                        </VStack>
                        <VStack>
                            <div className={styles.bottomLogoStack}>
                                <HStack style={{ paddingLeft: '14px', alignItems: 'center' }}>
                                    <Logo fill='#0066CC' width='70' height='20' onClick={handleHomeClick} />
                                    <VStack className={styles.bottomTextStack}>
                                        <div>Meet your next great hire...</div>
                                        <div>in minutes!</div>
                                    </VStack>
                                </HStack>
                            </div>
                            <div className={styles.bottomBackground} />
                        </VStack>
                    </VStack> : null
                }
            </Card>
            <HStack className={styles.headerContent}>
                {isTablet && <MenuOutlined
                    style={style === 'light' ?
                        { color: '#0066cc', fontSize: '30px' } :
                        { color: '#ffffff', fontSize: '30px' }}
                    onClick={handleHamburgerMenuClick}
                />}
                <Link className={[styles.logo, 'nohover'].join(' ')} aria-label='home page' href='/' style={{ display: 'flex' }}>{isTablet ?
                    <MobileLogo fill={style === 'light' ? '#0066cc' : '#ffffff'} /> :
                    <Logo fill={style === 'light' ? '#0066cc' : '#ffffff'} />
                }</Link>
                <Spacer />
                <Link className={[styles.navlink, styles.desktopLink].join(' ')} href='/about-us'>About Us</Link>
                <Link className={[styles.navlink, styles.desktopLink].join(' ')} href='/our-methodology'>Our Methodology</Link>
                <Link className={[styles.navlink, styles.desktopLink].join(' ')} href='/#portfolio'>Portfolio</Link>
                <Link className={[styles.navlink, styles.desktopLink].join(' ')} href='/#services'>Services</Link>
                <Link className={[styles.navlink, styles.desktopLink].join(' ')} href='/#our-expertise'>Our Expertise</Link>
                <Link className={[styles.navlink, styles.desktopLink].join(' ')} href='/#how-to-get-started'>How To Get Started</Link>
                <Link className={[styles.navlink, styles.desktopLink].join(' ')} href='/#how-we-do-it'>How We Do It</Link>
                <Spacer />
                <Button className={styles.contactUsButton} onClick={() => router.push('/#contact-us')}>Contact Us</Button>
            </HStack>
        </Page.Header >
    );
};

export default Header;
